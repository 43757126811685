/*

? First, let's import the interface that will describe the shape of our services object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the services object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const service_data: StateInterface["service"] = {
	_id: "62e4dcc7893a5fc8c8b287c4",
	slug: "interiors-for-vaishnavi-terraces",
	seo: {
		keywords: ["interiors", "vaishnavi", "terraces"],
		meta_title: "Interiors for Vaishnavi Terraces",
		meta_description: "Interiors for Vaishnavi Terraces",
	},
	search_metrics: {
		search_string: "interiors for vaishnavi terraces",
		search_points: 1,
		search_string_delimiter: "interiors for vaishnavi terraces",
		location_coordinates: [12.903869517218652, 77.5996545779337],
		search_filter_hashes: [],
	},
	service_category: "service",
	service_type: ["Infrastructure", "Property", "Other"],
	service_subtype: [],
	other_service_subtype: "",
	title: "Interiors for Vaishnavi Terraces",
	location: {
		title: "JP Nagar",
		pincode: "560037",
	},
	areas_of_operation: [
		{
			title: "JP Nagar",
			pincode: "560037",
			location_coordinates: [12.903869517218652, 77.5996545779337],
		},
	],
	description: "We have done amazing interiors for multiple apartments in Vaishnavi Terrace",
	service_provider: {
		name: "Tripcat",
		description: "We have done amazing interiors for multiple apartments in Vaishnavi Terrace",
		year_established: 2019,
	},
	service_specifications: ["Exotic wood", "Full HD colors", "Fancy false ceilings"],
	price: {
		min: 100,
		max: 200,
		discounted: {
			min: 0,
			max: 0,
		},
		currency: "INR",
		show_price: false,
	},
	tags: ["interiors"],
	cover_image: {
		url: "https://tripcat.com/cover.png",
		caption: "Tripcat",
	},
	thumbnail_image: {
		url: "https://tripcat.com/thumbnail.png",
		caption: "Tripcat",
	},
	images: [],
	pdf: "https://tripcat.com/pdf.pdf",
	status: "active",
	assigned_to: [],
	gmaps_embed_url:
		"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.302663905928!2d77.598268!3d12.902856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15199fc6e967%3A0x8bb3e0c24a6df98b!2sVaishnavi%20Terrace%2C%20Dollars%20Colony%2C%20Phase%204%2C%20J.%20P.%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560076!5e0!3m2!1sen!2sin!4v1608711353602!5m2!1sen!2sin",
	views: [],
	related_properties: [],
	show_contact_details: true,
	created_by: {
		is_business_profile: true,
		user_id: "624d2a7ffdcb2f276c216f83",
		business_profile_id: "624d2a7ffdcb2f276c216f83",
	},
	flags: [],
	listedBy: "",
	created_at: "2020-01-01T00:00:00.000Z",
	boost_information: {
		active: false,
		starts_at: "2100-01-01T00:00:00.000Z",
		ends_at: "2100-01-01T00:00:00.000Z",
		boost_id: "62b2e32e99a2279a2dd33960",
		total_amount: 0,
	},
	published: true,
	saved_content: [],
	test: false,
	status_history: [],
	show_in_featured: false,
	is_public: true,
	published_at: "2020-01-01T00:00:00.000Z",
	deleted: false,
	deleted_at: "2020-01-01T00:00:00.000Z",
	updated_at: "2020-01-01T00:00:00.000Z",
};

export const service = service_data;
export default service_data;
