/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const boost: StateInterface["boost"] = {
	_id: "62b2ed6299a2279a2dd33961",
	boosted_entity_type: "property",
	boosted_entity_id: "62b2ed6299a2279a2dd33961",
	boosted_by: {
		is_business_profile: false,
		user_id: "62a0a8e45503a8e31dcdab1a",
		business_profile_id: "62c82522fc94685e78844935",
	},
	boost_location: [],
	boost_start_date: new Date().toLocaleDateString(),
	boost_end_date: new Date().toLocaleDateString(),
	trigger_start_date: new Date().toLocaleDateString(),
	trigger_end_date: new Date().toLocaleDateString(),
	active: false,
	budget: 0,
	total_amount: 0,
	average_hourly_rate: 0,
	created_at: new Date().toLocaleDateString(),
	updated_at: new Date().toLocaleDateString(),
	cancelled: false,
	cancelled_at: new Date().toLocaleDateString(),
	mobile_step: 0,
	boost_snack_state: false,
	boost_snack_message: "",
	boost_snack_severity: "success",
	total_hours: 0,
	temp_weeks_count: 0,
	boost_weeks_count: 0,
	detail_error: null,
	temp_boost_end_date: "",
	temp_boost_start_date: "",
	location_error: null,
	temp_boost_location: [],
	selected_location: undefined,
	temp_points: 0,
	points_error: null,
	boost_minimum_points: 0,
	can_submit: false,
	boost_location_accordion: false,
	boost_points_accordion: false,
	entity_title: "",
};
