import StateInterface from "../state-interface";

export const account_setting_loading: StateInterface["account_setting_loading"] = {
	loading_personal_info: true,
	loading_kyc: true,
	loading_subscriptions: true,
	loading_subscriptions_plans: true,
	loading_cash_transactions: true,
	loading_beegru_points_transactions: true,
	loading_preferences: true,
	loading_rewards: true,
};
