import StateInterface from "../state-interface";

export const search_animation_settings: StateInterface["search_animation_settings"] = {
	StartAnimate: false,
	TriggerSearch: false,
	ResetSearch: false,
	HomeAccordion: false,
	PropertySubCategory: ["Budget", "Status", "Listed By", "Facing direction"],
	OpenLocationDrawer: false,
};
