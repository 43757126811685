/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const date = new Date();
const hourlyPrice = 0.065;
date.setHours(0, 0, 0, 0);
const extraMins = 1000 * 60 * 60;
const weeklyDate = new Date(date.getTime() + 168 * 60 * 60 * 1000).toISOString();
const hours = (new Date(weeklyDate).getTime() - date.getTime()) / 3600000;
const exactPoints = hours * hourlyPrice;
const minimimPoints = Math.ceil(exactPoints);

export const boost: StateInterface["boost"] = {
	_id: "",
	property_id: "",
	manual_override_data: [],
	boost_property_detail: null,
	boost_property_loading: false,
	boost_submit_loading: false,
	boosted_entity_type: "property",
	boosted_entity_id: "671610eb799b5da0dfd5c60d",
	boosted_by: {
		is_business_profile: false,
		user_id: "",
		business_profile_id: "",
	},
	boost_location: [],
	boost_end_date: weeklyDate,
	boost_start_date: new Date().toISOString(),
	trigger_start_date: new Date().toISOString(),
	trigger_end_date: new Date().toISOString(),
	active: false,
	budget: 0,
	total_amount: exactPoints,
	average_hourly_rate: hourlyPrice,
	created_at: new Date().toISOString(),
	updated_at: new Date().toISOString(),
	cancelled: false,
	cancelled_at: new Date().toISOString(),
	// property modal state
	mobile_step: 1,
	boost_modal_state: false,
	// property boost snackbar data
	boost_snack_message: "",
	boost_snack_state: false,
	boost_snack_severity: "success",
	// property boost temp data
	// property boost detail temp data
	total_hours: hours,
	detail_error: null,
	temp_weeks_count: 1,
	boost_weeks_count: 1,
	temp_boost_end_date: weeklyDate,
	temp_boost_start_date: new Date().toISOString(),
	// property boost audience temp data
	location_error: null,
	selected_location: null,
	temp_boost_location: [],
	// property boost points detail
	temp_points: 0,
	points_error: null,
	boost_minimum_points: minimimPoints,
	// property boost summary
	can_submit: false,
	boost_location_accordion: false,
	boost_points_accordion: false,
	entity_title: "",
};
