import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const construction_options: Array<SearchFilterOptionText> = [
	{
		id: "civil_contractor",
		title: "Civil Contractor",
		value: "civil_contractor",
	},
	{
		id: "masonry_and_concrete",
		title: "Masonry and Concrete",
		value: "masonry_and_concrete",
	},
	{
		id: "earth_moving",
		title: "Earth Moving",
		value: "earth_moving",
	},
	{
		id: "demolition",
		title: "Demolition",
		value: "demolition",
	},
	{
		id: "fabrication",
		title: "Fabrication",
		value: "fabrication",
	},
	{
		id: "hvac_installation",
		title: "HVAC Installation",
		value: "hvac_installation",
	},
	{
		id: "electrical",
		title: "Electrical",
		value: "electrical",
	},
	{
		id: "plumbing",
		title: "Plumbing",
		value: "plumbing",
	},
	{
		id: "solar_panel_installation",
		title: "Solar Panel Installation",
		value: "solar_panel_installation",
	},
	{
		id: "borewell_drilling",
		title: "Borewell Drilling",
		value: "borewell_drilling",
	},
	{
		id: "rainwater_harvesting",
		title: "Rainwater Harvesting",
		value: "rainwater_harvesting",
	},
	{
		id: "fire_safety",
		title: "Fire Safety",
		value: "fire_safety",
	},
	{
		id: "structural_engineering",
		title: "Structural Engineering",
		value: "structural_engineering",
	},
	{
		id: "architecture",
		title: "Architecture",
		value: "architecture",
	},
	{
		id: "painting",
		title: "Painting",
		value: "painting",
	},
	{
		id: "carpentry_or_woodwork",
		title: "Carpentry / Woodwork",
		value: "carpentry_or_woodwork",
	},
	{
		id: "facade_and_exterior_panels",
		title: "Facade and Exterior Panels",
		value: "facade_and_exterior_panels",
	},
	{
		id: "vaastu_consultant",
		title: "Vaastu Consultant",
		value: "vaastu_consultant",
	},
	{
		id: "material_supply",
		title: "Material Supply",
		value: "material_supply",
	},
	{
		id: "landscaping",
		title: "Landscaping",
		value: "landscaping",
	},
	{
		id: "pool_consultants",
		title: "Pool Consultants",
		value: "pool_consultants",
	},
	{
		id: "sewage_treatment_plant",
		title: "Sewage Treatment Plant",
		value: "sewage_treatment_plant",
	},
	{
		id: "lift_installation",
		title: "Lift Installation",
		value: "lift_installation",
	},
	{
		id: "dg_installation",
		title: "DG Installation",
		value: "dg_installation",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const construction_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "civil_contractor",
		title: "Civil Contractor",
		value: "civil_contractor",
		is_selected: true,
	},
	{
		id: "masonry_and_concrete",
		title: "Masonry and Concrete",
		value: "masonry_and_concrete",
		is_selected: false,
	},
	{
		id: "earth_moving",
		title: "Earth Moving",
		value: "earth_moving",
		is_selected: false,
	},
	{
		id: "demolition",
		title: "Demolition",
		value: "demolition",
		is_selected: false,
	},
	{
		id: "fabrication",
		title: "Fabrication",
		value: "fabrication",
		is_selected: false,
	},
	{
		id: "hvac_installation",
		title: "HVAC Installation",
		value: "hvac_installation",
		is_selected: false,
	},
	{
		id: "electrical",
		title: "Electrical",
		value: "electrical",
		is_selected: false,
	},
	{
		id: "plumbing",
		title: "Plumbing",
		value: "plumbing",
		is_selected: false,
	},
	{
		id: "solar_panel_installation",
		title: "Solar Panel Installation",
		value: "solar_panel_installation",
		is_selected: false,
	},
	{
		id: "borewell_drilling",
		title: "Borewell Drilling",
		value: "borewell_drilling",
		is_selected: false,
	},
	{
		id: "rainwater_harvesting",
		title: "Rainwater Harvesting",
		value: "rainwater_harvesting",
		is_selected: false,
	},
	{
		id: "fire_safety",
		title: "Fire Safety",
		value: "fire_safety",
		is_selected: false,
	},
	{
		id: "structural_engineering",
		title: "Structural Engineering",
		value: "structural_engineering",
		is_selected: false,
	},
	{
		id: "architecture",
		title: "Architecture",
		value: "architecture",
		is_selected: false,
	},
	{
		id: "painting",
		title: "Painting",
		value: "painting",
		is_selected: false,
	},
	{
		id: "carpentry_or_woodwork",
		title: "Carpentry / Woodwork",
		value: "carpentry_or_woodwork",
		is_selected: false,
	},
	{
		id: "facade_and_exterior_panels",
		title: "Facade and Exterior Panels",
		value: "facade_and_exterior_panels",
		is_selected: false,
	},
	{
		id: "vaastu_consultant",
		title: "Vaastu Consultant",
		value: "vaastu_consultant",
		is_selected: false,
	},
	{
		id: "material_supply",
		title: "Material Supply",
		value: "material_supply",
		is_selected: false,
	},
	{
		id: "landscaping",
		title: "Landscaping",
		value: "landscaping",
		is_selected: false,
	},
	{
		id: "pool_consultants",
		title: "Pool Consultants",
		value: "pool_consultants",
		is_selected: false,
	},
	{
		id: "sewage_treatment_plant",
		title: "Sewage Treatment Plant",
		value: "sewage_treatment_plant",
		is_selected: false,
	},
	{
		id: "lift_installation",
		title: "Lift Installation",
		value: "lift_installation",
		is_selected: false,
	},
	{
		id: "dg_installation",
		title: "DG Installation",
		value: "dg_installation",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const cleaning_and_maintenance_options: Array<SearchFilterOptionText> = [
	{
		id: "deep_cleaning",
		title: "Deep Cleaning",
		value: "deep_cleaning",
	},
	{
		id: "kitchen_and_bathroom_cleaning",
		title: "Kitchen and Bathroom Cleaning",
		value: "kitchen_and_bathroom_cleaning",
	},
	{
		id: "pest_control",
		title: "Pest Control",
		value: "pest_control",
	},
	{
		id: "laundry_and_dry_cleaning",
		title: "Laundry and Dry Cleaning",
		value: "laundry_and_dry_cleaning",
	},
	{
		id: "exterior_cleaning",
		title: "Exterior Cleaning",
		value: "exterior_cleaning",
	},
	{
		id: "sofa_cleaning",
		title: "Sofa Cleaning",
		value: "sofa_cleaning",
	},
	{
		id: "car_wash",
		title: "Car Wash",
		value: "car_wash",
	},
	{
		id: "sneaker_care",
		title: "Sneaker Care",
		value: "sneaker_care",
	},
	{
		id: "pool_maintenance",
		title: "Pool Maintenance",
		value: "pool_maintenance",
	},
	{
		id: "gardening",
		title: "Gardening",
		value: "gardening",
	},
	{
		id: "carpet_cleaning",
		title: "Carpet Cleaning",
		value: "carpet_cleaning",
	},
	{
		id: "water_sump_and_tank_cleaning",
		title: "Water Sump and Tank Cleaning",
		value: "water_sump_and_tank_cleaning",
	},
	{
		id: "granite_polish",
		title: "Granite Polish",
		value: "granite_polish",
	},
	{
		id: "tiles_acid_wash",
		title: "Tiles Acid Wash",
		value: "tiles_acid_wash",
	},
	{
		id: "wood_polish",
		title: "Wood Polish",
		value: "wood_polish",
	},
	{
		id: "acp_sheet_cleaning",
		title: "ACP Sheet Cleaning",
		value: "acp_sheet_cleaning",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const cleaning_and_maintenance_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "deep_cleaning",
		title: "Deep Cleaning",
		value: "deep_cleaning",
		is_selected: true,
	},
	{
		id: "kitchen_and_bathroom_cleaning",
		title: "Kitchen and Bathroom Cleaning",
		value: "kitchen_and_bathroom_cleaning",
		is_selected: false,
	},
	{
		id: "pest_control",
		title: "Pest Control",
		value: "pest_control",
		is_selected: false,
	},
	{
		id: "laundry_and_dry_cleaning",
		title: "Laundry and Dry Cleaning",
		value: "laundry_and_dry_cleaning",
		is_selected: false,
	},
	{
		id: "exterior_cleaning",
		title: "Exterior Cleaning",
		value: "exterior_cleaning",
		is_selected: false,
	},
	{
		id: "sofa_cleaning",
		title: "Sofa Cleaning",
		value: "sofa_cleaning",
		is_selected: false,
	},
	{
		id: "car_wash",
		title: "Car Wash",
		value: "car_wash",
		is_selected: false,
	},
	{
		id: "sneaker_care",
		title: "Sneaker Care",
		value: "sneaker_care",
		is_selected: false,
	},
	{
		id: "pool_maintenance",
		title: "Pool Maintenance",
		value: "pool_maintenance",
		is_selected: false,
	},
	{
		id: "gardening",
		title: "Gardening",
		value: "gardening",
		is_selected: false,
	},
	{
		id: "carpet_cleaning",
		title: "Carpet Cleaning",
		value: "carpet_cleaning",
		is_selected: false,
	},
	{
		id: "water_sump_and_tank_cleaning",
		title: "Water Sump and Tank Cleaning",
		value: "water_sump_and_tank_cleaning",
		is_selected: false,
	},
	{
		id: "granite_polish",
		title: "Granite Polish",
		value: "granite_polish",
		is_selected: false,
	},
	{
		id: "tiles_acid_wash",
		title: "Tiles Acid Wash",
		value: "tiles_acid_wash",
		is_selected: false,
	},
	{
		id: "wood_polish",
		title: "Wood Polish",
		value: "wood_polish",
		is_selected: false,
	},
	{
		id: "acp_sheet_cleaning",
		title: "ACP Sheet Cleaning",
		value: "acp_sheet_cleaning",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const interiors_and_decor_options: Array<SearchFilterOptionText> = [
	{
		id: "interior_design",
		title: "Interior Design",
		value: "interior_design",
	},
	{
		id: "interior_painting",
		title: "Interior Painting",
		value: "interior_painting",
	},
	{
		id: "wallpaper_installation",
		title: "Wallpaper Installation",
		value: "wallpaper_installation",
	},
	{
		id: "flooring",
		title: "Flooring",
		value: "flooring",
	},
	{
		id: "home_automation",
		title: "Home Automation",
		value: "home_automation",
	},
	{
		id: "furniture",
		title: "Furniture",
		value: "furniture",
	},
	{
		id: "false_ceiling",
		title: "False Ceiling",
		value: "false_ceiling",
	},
	{
		id: "electrical_fixtures",
		title: "Electrical Fixtures",
		value: "electrical_fixtures",
	},
	{
		id: "lighting",
		title: "Lighting",
		value: "lighting",
	},
	{
		id: "bathroom_interiors",
		title: "Bathroom Interiors",
		value: "bathroom_interiors",
	},
	{
		id: "partitions",
		title: "Partitions",
		value: "partitions",
	},
	{
		id: "blinds_and_curtains",
		title: "Blinds and Curtains",
		value: "blinds_and_curtains",
	},
	{
		id: "carpentry_or_woodwork",
		title: "Carpentry / Woodwork",
		value: "carpentry_or_woodwork",
	},
	{
		id: "wardrobe",
		title: "Wardrobe",
		value: "wardrobe",
	},
	{
		id: "fabrication",
		title: "Fabrication",
		value: "fabrication",
	},
	{
		id: "modular_kitchen",
		title: "Modular Kitchen",
		value: "modular_kitchen",
	},
	{
		id: "photo_and_art_framing",
		title: "Photo and Art Framing",
		value: "photo_and_art_framing",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const interiors_and_decor_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "interior_design",
		title: "Interior Design",
		value: "interior_design",
		is_selected: true,
	},
	{
		id: "painting",
		title: "Painting",
		value: "painting",
		is_selected: false,
	},
	{
		id: "wallpaper",
		title: "Wallpaper",
		value: "wallpaper",
		is_selected: false,
	},
	{
		id: "flooring",
		title: "Flooring",
		value: "flooring",
		is_selected: false,
	},
	{
		id: "home_automation",
		title: "Home Automation",
		value: "home_automation",
		is_selected: false,
	},
	{
		id: "furniture",
		title: "Furniture",
		value: "furniture",
		is_selected: false,
	},
	{
		id: "false_ceiling",
		title: "False Ceiling",
		value: "false_ceiling",
		is_selected: false,
	},
	{
		id: "electrical_fixtures",
		title: "Electrical Fixtures",
		value: "electrical_fixtures",
		is_selected: false,
	},
	{
		id: "lighting",
		title: "Lighting",
		value: "lighting",
		is_selected: false,
	},
	{
		id: "bathroom_interiors",
		title: "Bathroom Interiors",
		value: "bathroom_interiors",
		is_selected: false,
	},
	{
		id: "partitions",
		title: "Partitions",
		value: "partitions",
		is_selected: false,
	},
	{
		id: "blinds_and_curtains",
		title: "Blinds and Curtains",
		value: "blinds_and_curtains",
		is_selected: false,
	},
	{
		id: "carpentry_or_woodwork",
		title: "Carpentry / Woodwork",
		value: "carpentry_or_woodwork",
		is_selected: false,
	},
	{
		id: "wardrobe",
		title: "Wardrobe",
		value: "wardrobe",
		is_selected: false,
	},
	{
		id: "fabrication",
		title: "Fabrication",
		value: "fabrication",
		is_selected: false,
	},
	{
		id: "modular_kitchen",
		title: "Modular Kitchen",
		value: "modular_kitchen",
		is_selected: false,
	},
	{
		id: "photo_and_art_framing",
		title: "Photo and Art Framing",
		value: "photo_and_art_framing",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const home_utilities_options: Array<SearchFilterOptionText> = [
	{
		id: "lpg",
		title: "LPG",
		value: "lpg",
	},
	{
		id: "internet_and_networking",
		title: "Internet and Networking",
		value: "internet_and_networking",
	},
	{
		id: "water_supply",
		title: "Water Supply",
		value: "water_supply",
	},
	{
		id: "dish_and_cable",
		title: "Dish and Cable",
		value: "dish_and_cable",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const home_utilities_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "lpg",
		title: "LPG",
		value: "lpg",
		is_selected: true,
	},
	{
		id: "internet_and_networking",
		title: "Internet and Networking",
		value: "internet_and_networking",
		is_selected: false,
	},
	{
		id: "water_supply",
		title: "Water Supply",
		value: "water_supply",
		is_selected: false,
	},
	{
		id: "dish_and_cable",
		title: "Dish and Cable",
		value: "dish_and_cable",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const daily_home_care_and_security_options: Array<SearchFilterOptionText> = [
	{
		id: "cook",
		title: "Cook",
		value: "cook",
	},
	{
		id: "maid_or_housekeeping",
		title: "Maid / Housekeeping",
		value: "maid_or_housekeeping",
	},
	{
		id: "groceries_delivery",
		title: "Groceries Delivery",
		value: "groceries_delivery",
	},
	{
		id: "newspaper_and_magazine_delivery",
		title: "Newspaper and Magazine Delivery",
		value: "newspaper_and_magazine_delivery",
	},
	{
		id: "security_services",
		title: "Security Services",
		value: "security_services",
	},
	{
		id: "cctv",
		title: "CCTV",
		value: "cctv",
	},
	{
		id: "nanny",
		title: "Nanny",
		value: "nanny",
	},
	{
		id: "gardening_and_landscaping",
		title: "Gardening and Landscaping",
		value: "gardening_and_landscaping",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const daily_home_care_and_security_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "cook",
		title: "Cook",
		value: "cook",
		is_selected: true,
	},
	{
		id: "maid_or_housekeeping",
		title: "Maid / Housekeeping",
		value: "maid_or_housekeeping",
		is_selected: false,
	},
	{
		id: "groceries_delivery",
		title: "Groceries Delivery",
		value: "groceries_delivery",
		is_selected: false,
	},
	{
		id: "newspaper_and_magazine_delivery",
		title: "Newspaper and Magazine Delivery",
		value: "newspaper_and_magazine_delivery",
		is_selected: false,
	},
	{
		id: "security_services",
		title: "Security Services",
		value: "security_services",
		is_selected: false,
	},
	{
		id: "cctv",
		title: "CCTV",
		value: "cctv",
		is_selected: false,
	},
	{
		id: "nanny",
		title: "Nanny",
		value: "nanny",
		is_selected: false,
	},
	{
		id: "gardening_and_landscaping",
		title: "Gardening and Landscaping",
		value: "gardening_and_landscaping",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const home_and_appliance_repair_options: Array<SearchFilterOptionText> = [
	{
		id: "ac_repair",
		title: "AC Repair",
		value: "ac_repair",
	},
	{
		id: "refrigerator_repair",
		title: "Refrigerator Repair",
		value: "refrigerator_repair",
	},
	{
		id: "washing_machine_repair",
		title: "Washing Machine Repair",
		value: "washing_machine_repair",
	},
	{
		id: "stove_repair",
		title: "Stove Repair",
		value: "stove_repair",
	},
	{
		id: "microwave_repair",
		title: "Microwave Repair",
		value: "microwave_repair",
	},
	{
		id: "tv_repair",
		title: "TV Repair",
		value: "tv_repair",
	},
	{
		id: "water_purifier_repair",
		title: "Water Purifier Repair",
		value: "water_purifier_repair",
	},
	{
		id: "geyser_repair",
		title: "Geyser Repair",
		value: "geyser_repair",
	},
	{
		id: "computer_repair",
		title: "Computer Repair",
		value: "computer_repair",
	},
	{
		id: "mobile_repair",
		title: "Mobile Repair",
		value: "mobile_repair",
	},
	{
		id: "watch_repair",
		title: "Watch Repair",
		value: "watch_repair",
	},
	{
		id: "chimney_repair",
		title: "Chimney Repair",
		value: "chimney_repair",
	},
	{
		id: "mixer_and_grinder_repair",
		title: "Mixer and Grinder Repair",
		value: "mixer_and_grinder_repair",
	},
	{
		id: "lift_repair",
		title: "Lift Repair",
		value: "lift_repair",
	},
	{
		id: "solar_panel_repair",
		title: "Solar Panel Repair",
		value: "solar_panel_repair",
	},
	{
		id: "dg_repair",
		title: "DG Repair",
		value: "dg_repair",
	},
	{
		id: "borewell_and_motor_repair",
		title: "Borewell and Motor Repair",
		value: "borewell_and_motor_repair",
	},
	{
		id: "electrical_repair",
		title: "Electrical Repair",
		value: "electrical_repair",
	},
	{
		id: "plumbing_repair",
		title: "Plumbing Repair",
		value: "plumbing_repair",
	},
	{
		id: "carpentry_or_woodwork_repair",
		title: "Carpentry / Woodwork Repair",
		value: "carpentry_or_woodwork_repair",
	},
	{
		id: "roof_repair",
		title: "Roof Repair",
		value: "roof_repair",
	},
	{
		id: "window_repair",
		title: "Window Repair",
		value: "window_repair",
	},
	{
		id: "electronics_repair",
		title: "Electronics Repair",
		value: "electronics_repair",
	},
	{
		id: "knife_and_scissors_sharpening",
		title: "Knife and Scissors Sharpening",
		value: "knife_and_scissors_sharpening",
	},
	{
		id: "jewellery_repair",
		title: "Jewellery Repair",
		value: "jewellery_repair",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const home_and_appliance_repair_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "ac_repair",
		title: "AC Repair",
		value: "ac_repair",
		is_selected: true,
	},
	{
		id: "refrigerator_repair",
		title: "Refrigerator Repair",
		value: "refrigerator_repair",
		is_selected: false,
	},
	{
		id: "washing_machine_repair",
		title: "Washing Machine Repair",
		value: "washing_machine_repair",
		is_selected: false,
	},
	{
		id: "stove_repair",
		title: "Stove Repair",
		value: "stove_repair",
		is_selected: false,
	},
	{
		id: "microwave_repair",
		title: "Microwave Repair",
		value: "microwave_repair",
		is_selected: false,
	},
	{
		id: "tv_repair",
		title: "TV Repair",
		value: "tv_repair",
		is_selected: false,
	},
	{
		id: "water_purifier_repair",
		title: "Water Purifier Repair",
		value: "water_purifier_repair",
		is_selected: false,
	},
	{
		id: "geyser_repair",
		title: "Geyser Repair",
		value: "geyser_repair",
		is_selected: false,
	},
	{
		id: "computer_repair",
		title: "Computer Repair",
		value: "computer_repair",
		is_selected: false,
	},
	{
		id: "mobile_repair",
		title: "Mobile Repair",
		value: "mobile_repair",
		is_selected: false,
	},
	{
		id: "watch_repair",
		title: "Watch Repair",
		value: "watch_repair",
		is_selected: false,
	},
	{
		id: "chimney_repair",
		title: "Chimney Repair",
		value: "chimney_repair",
		is_selected: false,
	},
	{
		id: "mixer_and_grinder_repair",
		title: "Mixer and Grinder Repair",
		value: "mixer_and_grinder_repair",
		is_selected: false,
	},
	{
		id: "lift_repair",
		title: "Lift Repair",
		value: "lift_repair",
		is_selected: false,
	},
	{
		id: "solar_panel_repair",
		title: "Solar Panel Repair",
		value: "solar_panel_repair",
		is_selected: false,
	},
	{
		id: "dg_repair",
		title: "DG Repair",
		value: "dg_repair",
		is_selected: false,
	},
	{
		id: "borewell_and_motor_repair",
		title: "Borewell and Motor Repair",
		value: "borewell_and_motor_repair",
		is_selected: false,
	},
	{
		id: "electrical_repair",
		title: "Electrical Repair",
		value: "electrical_repair",
		is_selected: false,
	},
	{
		id: "plumbing_repair",
		title: "Plumbing Repair",
		value: "plumbing_repair",
		is_selected: false,
	},
	{
		id: "carpentry_or_woodwork_repair",
		title: "Carpentry / Woodwork Repair",
		value: "carpentry_or_woodwork_repair",
		is_selected: false,
	},
	{
		id: "roof_repair",
		title: "Roof Repair",
		value: "roof_repair",
		is_selected: false,
	},
	{
		id: "window_repair",
		title: "Window Repair",
		value: "window_repair",
		is_selected: false,
	},
	{
		id: "electronics_repair",
		title: "Electronics Repair",
		value: "electronics_repair",
		is_selected: false,
	},
	{
		id: "knife_and_scissors_sharpening",
		title: "Knife and Scissors Sharpening",
		value: "knife_and_scissors_sharpening",
		is_selected: false,
	},
	{
		id: "jewellery_repair",
		title: "Jewellery Repair",
		value: "jewellery_repair",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];
export const transport_options: Array<SearchFilterOptionText> = [
	{
		id: "packers_and_movers",
		title: "Packers and Movers",
		value: "packers_and_movers",
	},
	{
		id: "tempo_and_truck_hire",
		title: "Tempo and Truck Hire",
		value: "tempo_and_truck_hire",
	},
	{
		id: "cab_or_taxi",
		title: "Cab / Taxi",
		value: "cab_or_taxi",
	},
	{
		id: "car_or_bike_rental",
		title: "Car / Bike Rental",
		value: "car_or_bike_rental",
	},
	{
		id: "car_or_bike_repair",
		title: "Car / Bike Repair",
		value: "car_or_bike_repair",
	},
	{
		id: "driver",
		title: "Driver",
		value: "driver",
	},
	{
		id: "towing",
		title: "Towing",
		value: "towing",
	},
	{
		id: "courier_and_cargo",
		title: "Courier and Cargo",
		value: "courier_and_cargo",
	},
	{
		id: "travel_and_tourism",
		title: "Travel and Tourism",
		value: "travel_and_tourism",
	},
	{
		id: "shipping",
		title: "Shipping",
		value: "shipping",
	},
	{
		id: "train",
		title: "Train",
		value: "train",
	},
	{
		id: "air_cargo",
		title: "Air Cargo",
		value: "air_cargo",
	},
	{
		id: "crane",
		title: "Crane",
		value: "crane",
	},
	{
		id: "helicopter",
		title: "Helicopter",
		value: "helicopter",
	},
	{
		id: "private_jet",
		title: "Private Jet",
		value: "private_jet",
	},
	{
		id: "bus_rental",
		title: "Bus Rental",
		value: "bus_rental",
	},
	{
		id: "caravan_rental",
		title: "Caravan Rental",
		value: "caravan_rental",
	},
	{
		id: "tyre_maintenance",
		title: "Tyre Maintenance",
		value: "tyre_maintenance",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const transport_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "packers_and_movers",
		title: "Packers and Movers",
		value: "packers_and_movers",
		is_selected: true,
	},
	{
		id: "tempo_and_truck_hire",
		title: "Tempo and Truck Hire",
		value: "tempo_and_truck_hire",
		is_selected: false,
	},
	{
		id: "cab_or_taxi",
		title: "Cab / Taxi",
		value: "cab_or_taxi",
		is_selected: false,
	},
	{
		id: "cab_or_bike_rental",
		title: "Cab / Bike Rental",
		value: "cab_or_bike_rental",
		is_selected: false,
	},
	{
		id: "cab_or_bike_repair",
		title: "Cab / Bike Repair",
		value: "cab_or_bike_repair",
		is_selected: false,
	},
	{
		id: "driver",
		title: "Driver",
		value: "driver",
		is_selected: false,
	},
	{
		id: "towing",
		title: "Towing",
		value: "towing",
		is_selected: false,
	},
	{
		id: "courier_and_cargo",
		title: "Courier and Cargo",
		value: "courier_and_cargo",
		is_selected: false,
	},
	{
		id: "travel_and_tourism",
		title: "Travel and Tourism",
		value: "travel_and_tourism",
		is_selected: false,
	},
	{
		id: "shipping",
		title: "Shipping",
		value: "shipping",
		is_selected: false,
	},
	{
		id: "train",
		title: "Train",
		value: "train",
		is_selected: false,
	},
	{
		id: "air_cargo",
		title: "Air Cargo",
		value: "air_cargo",
		is_selected: false,
	},
	{
		id: "crane",
		title: "Crane",
		value: "crane",
		is_selected: false,
	},
	{
		id: "helicopter",
		title: "Helicopter",
		value: "helicopter",
		is_selected: false,
	},
	{
		id: "private_jet",
		title: "Private Jet",
		value: "private_jet",
		is_selected: false,
	},
	{
		id: "bus_rental",
		title: "Bus Rental",
		value: "bus_rental",
		is_selected: false,
	},
	{
		id: "caravan_rental",
		title: "Caravan Rental",
		value: "caravan_rental",
		is_selected: false,
	},
	{
		id: "tyre_maintenance",
		title: "Tyre Maintenance",
		value: "tyre_maintenance",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const legal_and_finance_options: Array<SearchFilterOptionText> = [
	{
		id: "legal_consultation",
		title: "Legal Consultation",
		value: "legal_consultation",
	},
	{
		id: "documentation",
		title: "Documentation",
		value: "documentation",
	},
	{
		id: "accounting_and_bookkeeping",
		title: "Accounting and Bookkeeping",
		value: "accounting_and_bookkeeping",
	},
	{
		id: "financial_services",
		title: "Financial Services",
		value: "financial_services",
	},
	{
		id: "insurance",
		title: "Insurance",
		value: "insurance",
	},
	{
		id: "loan_and_funding",
		title: "Loan and Funding",
		value: "loan_and_funding",
	},
	{
		id: "tax_filing_and_consultation",
		title: "Tax Filing and Consultation",
		value: "tax_filing_and_consultation",
	},
	{
		id: "investment_advisory",
		title: "Investment Advisory",
		value: "investment_advisory",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const legal_and_finance_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "legal_consultation",
		title: "Legal Consultation",
		value: "legal_consultation",
		is_selected: true,
	},
	{
		id: "liaisoning",
		title: "Liaisoning",
		value: "liaisoning",
		is_selected: false,
	},
	{
		id: "documentation",
		title: "Documentation",
		value: "documentation",
		is_selected: false,
	},
	{
		id: "accounting_and_bookkeeping",
		title: "Accounting and Bookkeeping",
		value: "accounting_and_bookkeeping",
		is_selected: false,
	},
	{
		id: "financial_services",
		title: "Financial Services",
		value: "financial_services",
		is_selected: false,
	},
	{
		id: "insurance",
		title: "Insurance",
		value: "insurance",
		is_selected: false,
	},
	{
		id: "loan_and_funding",
		title: "Loan and Funding",
		value: "loan_and_funding",
		is_selected: false,
	},
	{
		id: "tax_filing_and_consultation",
		title: "Tax Filing and Consultation",
		value: "tax_filing_and_consultation",
		is_selected: false,
	},
	{
		id: "investment_advisory",
		title: "Investment Advisory",
		value: "investment_advisory",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const grooming_and_other_personal_care_options: Array<SearchFilterOptionText> = [
	{
		id: "salon",
		title: "Salon",
		value: "salon",
	},
	{
		id: "spa",
		title: "Spa",
		value: "spa",
	},
	{
		id: "tailor",
		title: "Tailor",
		value: "tailor",
	},
	{
		id: "optician",
		title: "Optician",
		value: "optician",
	},
	{
		id: "stylist",
		title: "Stylist",
		value: "stylist",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const grooming_and_other_personal_care_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "salon",
		title: "Salon",
		value: "salon",
		is_selected: true,
	},
	{
		id: "spa",
		title: "Spa",
		value: "spa",
		is_selected: false,
	},
	{
		id: "tailor",
		title: "Tailor",
		value: "tailor",
		is_selected: false,
	},
	{
		id: "optician",
		title: "Optician",
		value: "optician",
		is_selected: false,
	},
	{
		id: "stylist",
		title: "Stylist",
		value: "stylist",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const liaisoning_options: Array<SearchFilterOptionText> = [
	{
		id: "liaisoning",
		title: "Liaisoning",
		value: "liaisoning",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const liaisoning_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "liaisoning",
		title: "Liaisoning",
		value: "liaisoning",
		is_selected: true,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const social_service_options: Array<SearchFilterOptionText> = [
	{
		id: "social_service",
		title: "Social Service",
		value: "social_service",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const social_service_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "social_service",
		title: "Social Service",
		value: "social_service",
		is_selected: true,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const food_options: Array<SearchFilterOptionText> = [
	{
		id: "restaurant",
		title: "Restaurant",
		value: "restaurant",
	},
	{
		id: "catering",
		title: "Catering",
		value: "catering",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const food_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "restaurant",
		title: "Restaurant",
		value: "restaurant",
		is_selected: true,
	},
	{
		id: "catering",
		title: "Catering",
		value: "catering",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const hospitality_options: Array<SearchFilterOptionText> = [
	{
		id: "hotels_and_resorts",
		title: "Hotel and Resorts",
		value: "hotels_and_resorts",
	},
	{
		id: "service_apartments",
		title: "Service Apartments",
		value: "service_apartments",
	},
	{
		id: "home_rentals_long_term",
		title: "Home Rentals (Long Term)",
		value: "home_rentals_long_term",
	},
	{
		id: "home_rentals_short_term",
		title: "Home Rentals (Short Term)",
		value: "home_rentals_short_term",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const hospitality_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "hotels_and_resorts",
		title: "Hotel and Resorts",
		value: "hotels_and_resorts",
		is_selected: true,
	},
	{
		id: "service_apartments",
		title: "Service Apartments",
		value: "service_apartments",
		is_selected: false,
	},
	{
		id: "home_rentals_long_term",
		title: "Home Rentals (Long Term)",
		value: "home_rentals_long_term",
		is_selected: false,
	},
	{
		id: "home_rentals_short_term",
		title: "Home Rentals (Short Term)",
		value: "home_rentals_short_term",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const other_options: Array<SearchFilterOptionText> = [
	{
		id: "pet_care",
		title: "Pet Care",
		value: "pet_care",
	},
	{
		id: "photography_or_videography",
		title: "Photography / Videography",
		value: "photography_or_videography",
	},
	{
		id: "information_technology_open_parenthesis_it_close_parenthesis",
		title: "Information Technology (IT)",
		value: "information_technology_open_parenthesis_it_close_parenthesis",
	},
	{
		id: "sports_and_fitness",
		title: "Sports and Fitness",
		value: "sports_and_fitness",
	},
	{
		id: "drone_service",
		title: "Drone Service",
		value: "drone_service",
	},
	{
		id: "storage_space",
		title: "Storage Space",
		value: "storage_space",
	},
	{
		id: "dentist",
		title: "Dentist",
		value: "dentist",
	},
	{
		id: "tattoo",
		title: "Tattoo",
		value: "tattoo",
	},
	{
		id: "event_management",
		title: "Event Management",
		value: "event_management",
	},
	{
		id: "tuition",
		title: "Tuition",
		value: "tuition",
	},
	{
		id: "personal_coaching",
		title: "Personal Coaching",
		value: "personal_coaching",
	},
	{
		id: "cobbler",
		title: "Cobbler",
		value: "cobbler",
	},
	{
		id: "tree_trimming_and_maintenance",
		title: "Tree Trimming and Maintenance",
		value: "tree_trimming_and_maintenance",
	},
	{
		id: "printing_services",
		title: "Printing Services",
		value: "printing_services",
	},
	{
		id: "astrology",
		title: "Astrology",
		value: "astrology",
	},
	{
		id: "religious_services",
		title: "Religious Services",
		value: "religious_services",
	},
	{
		id: "labour_supply",
		title: "Labour Supply",
		value: "labour_supply",
	},
	{
		id: "water_proofing",
		title: "Water Proofing",
		value: "water_proofing",
	},
	{
		id: "other",
		title: "Other",
		value: "other",
	},
];

export const other_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "pet_care",
		title: "Pet Care",
		value: "pet_care",
		is_selected: true,
	},
	{
		id: "photography_or_videography",
		title: "Photography / Videography",
		value: "photography_or_videography",
		is_selected: false,
	},
	{
		id: "information_technology_open_parenthesis_it_close_parenthesis_and_software",
		title: "Information Technology (IT) and Software",
		value: "information_technology_open_parenthesis_it_close_parenthesis_and_software",
		is_selected: false,
	},
	{
		id: "sports_and_fitness",
		title: "Sports and Fitness",
		value: "sports_and_fitness",
		is_selected: false,
	},
	{
		id: "drone_service",
		title: "Drone Service",
		value: "drone_service",
		is_selected: false,
	},
	{
		id: "storage_space",
		title: "Storage Space",
		value: "storage_space",
		is_selected: false,
	},
	{
		id: "dentist",
		title: "Dentist",
		value: "dentist",
		is_selected: false,
	},
	{
		id: "tattoo",
		title: "Tattoo",
		value: "tattoo",
		is_selected: false,
	},
	{
		id: "event_management",
		title: "Event Management",
		value: "event_management",
		is_selected: false,
	},
	{
		id: "tuition",
		title: "Tuition",
		value: "tuition",
		is_selected: false,
	},
	{
		id: "personal_coaching",
		title: "Personal Coaching",
		value: "personal_coaching",
		is_selected: false,
	},
	{
		id: "cobbler",
		title: "Cobbler",
		value: "cobbler",
		is_selected: false,
	},
	{
		id: "tree_trimming_and_maintenance",
		title: "Tree Trimming and Maintenance",
		value: "tree_trimming_and_maintenance",
		is_selected: false,
	},
	{
		id: "printing_services",
		title: "Printing Services",
		value: "printing_services",
		is_selected: false,
	},
	{
		id: "astrology",
		title: "Astrology",
		value: "astrology",
		is_selected: false,
	},
	{
		id: "religious_services",
		title: "Religious Services",
		value: "religious_services",
		is_selected: false,
	},
	{
		id: "labour_supply",
		title: "Labour Supply",
		value: "labour_supply",
		is_selected: false,
	},
	{
		id: "water_proofing",
		title: "Water Proofing",
		value: "water_proofing",
		is_selected: false,
	},
	{
		id: "other",
		title: "Other",
		value: "other",
		is_selected: false,
	},
];

export const _x_service_subtype: SearchFilter = {
	id: "service_subtype",
	title: "Service Subtype",
	level: 3,
	index: 23,
	presentation_type: "chips",
	multi: false,
	affects: [],
	affected_by: ["service_type"],
	priority: 3,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		...construction_options,
		...cleaning_and_maintenance_options,
		...interiors_and_decor_options,
		...home_utilities_options,
		...daily_home_care_and_security_options,
		...home_and_appliance_repair_options,
		...transport_options,
		...legal_and_finance_options,
		...grooming_and_other_personal_care_options,
		...liaisoning_options,
		...social_service_options,
		...food_options,
		...hospitality_options,
		...other_options,
	] as Array<SearchFilterOptionText>,
	active_options: [...construction_options_active] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "services",
	db_field: "service_subtype",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
