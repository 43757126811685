import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _w_service_type: SearchFilter = {
	id: "service_type",
	title: "Service Type",
	level: 2,
	index: 22,
	presentation_type: "chips",
	multi: false,
	affects: ["service_subtype"],
	affected_by: ["service_category"],
	priority: 2,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "construction",
			title: "Construction",
			value: "construction",
		},
		{
			id: "cleaning_and_maintenance",
			title: "Cleaning and Maintenance",
			value: "cleaning_and_maintenance",
		},
		{
			id: "interiors_and_decor",
			title: "Interiors and Decor",
			value: "interiors_and_decor",
		},
		{
			id: "home_utilities",
			title: "Home Utilities",
			value: "home_utilities",
		},
		{
			id: "daily_home_care_and_security",
			title: "Daily Home Care and Security",
			value: "daily_home_care_and_security",
		},
		{
			id: "home_and_appliance_repair",
			title: "Home and Appliance Repair",
			value: "home_and_appliance_repair",
		},
		{
			id: "transport",
			title: "Transport",
			value: "transport",
		},
		{
			id: "legal_and_finance",
			title: "Legal and Finance",
			value: "legal_and_finance",
		},
		{
			id: "grooming_and_other_personal_care",
			title: "Grooming and Other Personal Care",
			value: "grooming_and_other_personal_care",
		},
		{
			id: "liaisoning",
			title: "Liaisoning",
			value: "liaisoning",
		},
		{
			id: "social_service",
			title: "Social Service",
			value: "social_service",
		},
		{
			id: "food",
			title: "Food",
			value: "food",
		},
		{
			id: "hospitality",
			title: "Hospitality",
			value: "hospitality",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "construction",
			title: "Construction",
			value: "construction",
			is_selected: true,
		},
		{
			id: "cleaning_and_maintenance",
			title: "Cleaning and Maintenance",
			value: "cleaning_and_maintenance",
			is_selected: false,
		},
		{
			id: "interiors_and_decor",
			title: "Interiors and Decor",
			value: "interiors_and_decor",
			is_selected: false,
		},
		{
			id: "home_utilities",
			title: "Home Utilities",
			value: "home_utilities",
			is_selected: false,
		},
		{
			id: "daily_home_care_and_security",
			title: "Daily Home Care and Security",
			value: "daily_home_care_and_security",
			is_selected: false,
		},
		{
			id: "home_and_appliance_repair",
			title: "Home and Appliance Repair",
			value: "home_and_appliance_repair",
			is_selected: false,
		},
		{
			id: "transport",
			title: "Transport",
			value: "transport",
			is_selected: false,
		},
		{
			id: "legal_and_finance",
			title: "Legal and Finance",
			value: "legal_and_finance",
			is_selected: false,
		},
		{
			id: "grooming_and_other_personal_care",
			title: "Grooming and Other Personal Care",
			value: "grooming_and_other_personal_care",
			is_selected: false,
		},
		{
			id: "liaisoning",
			title: "Liaisoning",
			value: "liaisoning",
			is_selected: false,
		},
		{
			id: "social_service",
			title: "Social Service",
			value: "social_service",
			is_selected: false,
		},
		{
			id: "food",
			title: "Food",
			value: "food",
			is_selected: false,
		},
		{
			id: "hospitality",
			title: "Hospitality",
			value: "hospitality",
			is_selected: false,
		},
		{
			id: "other",
			title: "Other",
			value: "other",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "services",
	db_field: "service_type",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
