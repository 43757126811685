/*

? First, let's import the interface that will describe the shape of our services object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the services object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const service_data: StateInterface["new_service_data"] = {
	_id: "",
	slug: "",
	seo: {
		keywords: [],
		meta_title: "",
		meta_description: "",
	},
	search_metrics: {
		search_string: "",
		search_points: 0,
		search_string_delimiter: "",
		location_coordinates: [1,1],
		search_filter_hashes: [],
	},
	service_category: "",
	service_type: [],
	service_subtype: [],
	other_service_subtype: "",
	title: "",
	location: {
		title: "",
		pincode: "",
	},
	areas_of_operation: [],
	description: "",
	service_provider: {
		name: "",
		description: "",
		year_established: 0,
	},
	service_specifications: [],
	price: {
		min: 0,
		max: 0,
		discounted: {
			min: 0,
			max: 0,
		},
		currency: "",
		show_price: false,
	},
	tags: [],
	cover_image: {
		url: "",
		caption: "",
	},
	thumbnail_image: {
		url: "",
		caption: "",
	},
	images: [],
	image_upload_completed: false,
	pdf: "",
	status: "",
	assigned_to: [],
	gmaps_embed_url: "",
	views: [],
	related_properties: [],
	show_contact_details: true,
	created_by: {
		is_business_profile: true,
		user_id: "",
		business_profile_id: "",
	},
	listedBy: "",
	flags: [],
	boost_information: {
		active: false,
		starts_at: "2100-01-01T00:00:00.000Z",
		ends_at: "2100-01-01T00:00:00.000Z",
		boost_id: "",
		total_amount: 0,
	},
	created_at: "",
	published: true,
	saved_content: [],
	is_public: true,
	published_at: "",
	deleted: false,
	deleted_at: "",
	updated_at: "",
	currentPage: "",
	snack_value: false,
	snack_text: "",
};

export const new_service_data = service_data;
export default service_data;
