/* State Interface Imports */

import StateInterface from "../state-interface";
import { property } from "./property";

/* Boost Property Page Default State Data */

export const boost_property_page: StateInterface["boost_property_page"] = {
  property_data: property,
  search_points: [],
  boostData: {
    _id: "62b2ed6299a2279a2dd33961",
    boosted_entity_type: "property",
    boosted_entity_id: "62b2ed6299a2279a2dd33961",
    boosted_by: {
      is_business_profile: false,
      user_id: "62a0a8e45503a8e31dcdab1a",
      business_profile_id: "62c82522fc94685e78844935",
    },
    boost_location: [],
    boost_start_date: new Date().toLocaleDateString(),
    boost_end_date: new Date().toLocaleDateString(),
    trigger_start_date: new Date().toLocaleDateString(),
    trigger_end_date: new Date().toLocaleDateString(),
    active: false,
    budget: 0,
    total_amount: 0,
    average_hourly_rate: 0,
    created_at: new Date().toLocaleDateString(),
    updated_at: new Date().toLocaleDateString(),
    cancelled: false,
    cancelled_at: new Date().toLocaleDateString(),
  },
};
